import React from 'react';
import PropTypes from "prop-types";
import { Button, Checkbox, Table, Tag } from 'antd';
import { isEmptyValue } from '../../utils/JsObjectHelper';
import { withTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import FormNameValueManipulationModal from './FormNameValueManipulationModal';
import { getTextColorFromBackgroundHex } from '../../utils/ColorHelper';
//import { number } from 'prop-types';

const cloneDeep = require('lodash.clonedeep');

class FormNameValueItem extends React.Component {


    constructor(props) {
        super(props);
        const value = props.value //props.fileUrlArrayData.length || null;
        const useColor = (!isUndefined(props.useColor) ? props.useColor : false);
        const useTypes = (!isUndefined(props.useTypes) ? props.useTypes : true);

        this.state = {
            // Same name as valuePropName in getFieldDecorator ('value' is default):
            // https://ant.design/components/form/?locale=en-US#getFieldDecorator(id,-options)-parameters
            value,
            isModalEdit: false,
            isModalOpen: false,
            modalItemData: null,
            useColor: useColor,
            useTypes: useTypes
        };
    }

    handleChange = (value) => {
        //console.log(value);
        this.setState({ value: value });
        const { onChange } = this.props;
        console.log(value);
        if (onChange) {
            // This will provide the form with changes
            onChange(value);
        }
    };

    itemAdded = (itemName, itemValue, itemColor) => {
        let newItemObj = (this.state.useColor ? { name: itemName, value: itemValue, color: itemColor } : { name: itemName, value: itemValue });

        let thisItemArray = cloneDeep(this.state.value);
        if (isUndefined(thisItemArray)) {
            thisItemArray = [];
            thisItemArray.push(newItemObj);
        } else {
            thisItemArray.push(newItemObj);
        }
        this.setState({ isModalEdit: false, isModalOpen: false, modalItemData: null });
        this.handleChange(thisItemArray);
    };

    itemUpdated = (itemName, itemValue, itemColor, prevItem) => {
        let thisItemArray = cloneDeep(this.state.value);
        let newItemObj = (this.state.useColor ? { name: itemName, value: itemValue, color: itemColor } : { name: itemName, value: itemValue });
        thisItemArray = thisItemArray.filter(f => !(
            f.name === prevItem.name && f.value === prevItem.value
        ));
        thisItemArray.push(newItemObj);

        this.setState({ isModalEdit: false, isModalOpen: false, modalItemData: null });
        this.handleChange(thisItemArray);
    };

    onItemEdit = (record) => {
        this.setState({ isModalEdit: true, isModalOpen: true, modalItemData: record });
    };

    onItemDeleted = (record) => {
        let thisItemArray = cloneDeep(this.state.value);
        if (!isUndefined(thisItemArray)) {
            thisItemArray = thisItemArray.filter(f => !(
                f.name === record.name && f.value === record.value
            ));
            this.handleChange(thisItemArray);
        }
    };

    onModalHide = () => {
        this.setState({ isModalEdit: false, isModalOpen: false, modalItemData: null });
    }

    onUnwatch = (record) => {
        let thisItemArray = cloneDeep(this.state.value);
        let item = thisItemArray.find(f => f.name === record.name && f.value === record.value);
        if (item.hide) {
            item.hide = false;
        } else {
            item.hide = true;
        }
        this.handleChange(thisItemArray);
    }

    render() {
        const { t } = this.props;

        let columns = [
            {
                title: t('app.genericControls.formNameValueItem.tabName'),
                dataIndex: 'name',
                key: 'name',
                //width: 150,
                //fixed: 'left',
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortOrder: 'ascend',
                render: (text, record) => {
                    return (
                        <span title={record.name} style={{textDecoration: record.hide ? 'line-through' : 'none'}}>
                            {record.name}
                        </span>
                    );
                }
            },
            {
                title: t('app.genericControls.formNameValueItem.tabValue'),
                dataIndex: 'value',
                key: 'value',
                render: (text, record) => {
                    if (typeof record.value === 'boolean') {
                        return (
                            <span>
                                <Checkbox checked={record.value}></Checkbox>
                            </span>
                        );
                    } else if (typeof record.value === 'number') {
                        return (
                            <span>
                                {(isEmptyValue(record.value) ? "" : record.value)}
                            </span>
                        );
                    } else {
                        return (
                            <span title={record.value} style={{textDecoration: record.hide ? 'line-through' : 'none'}}>
                                {(isEmptyValue(record.value) ? "" : (record.value.length > 50 ? `${record.value.substring(0, 50)}...` : record.value))}
                            </span>
                        );
                    }
                }
            }
        ];

        //for color push color column
        if (this.state.useColor) {
            columns.push(
                {
                    title: t('app.genericControls.formNameValueItem.tabColor'),
                    dataIndex: 'color',
                    key: 'color',
                    render: (text, record) => {
                        let tagTextColor = getTextColorFromBackgroundHex(record.color);
                        return (
                            <Tag color={record.hide ? 'gray' : record.color} style={{ color: tagTextColor,textDecoration: record.hide ? 'line-through' : 'none' }}>
                                {record.name}
                            </Tag>
                        );
                    }
                }
            );
        }

        //actions last
        columns.push(
            {
                title: '',
                dataIndex: 'action',
                width: '96px',
                render: (text, record) => {
                    return [
                        <Button
                            key="btnEdit"
                            onClick={() => this.onItemEdit(record)}
                            icon={<EditOutlined />} size="small" type='primary' style={{ color: 'green', borderColor: 'green' }} ghost></Button>,
                        <Button
                            key="btnWath"
                            onClick={() => this.onUnwatch(record)}
                            title={record.hide ? t('app.genericControls.formNameValueItem.show') : t('app.genericControls.formNameValueItem.hide')}                            
                            icon={record.hide ? <EyeOutlined /> : <EyeInvisibleOutlined />} size="small" type='primary' ghost></Button>,
                        <Button
                            key="btnDelete"
                            onClick={() => this.onItemDeleted(record)}
                            icon={<DeleteOutlined />} size="small" type="danger" ghost></Button>
                    ];
                }
            }
        );

        return <Table
            key="nameValueTable"
            columns={columns}
            dataSource={this.state.value}
            size="small"
            pagination={false}
            title={() => <FormNameValueManipulationModal
                onItemAdd={this.itemAdded}
                onItemUpdated={this.itemUpdated}
                onModalHide={this.onModalHide}
                isEdit={this.state.isModalEdit}
                isOpen={this.state.isModalOpen}
                itemValues={this.state.modalItemData}
                useColor={this.state.useColor}
                useTypes={this.state.useTypes}
            >
            </FormNameValueManipulationModal>}
            scroll={{ x: true }} //, y: 300 
            rowKey={record => {
                //console.log(`${record.name}|${record.value}`);
                return `${record.name}|${record.value}`;
            }}
        />;
    }
}

export default withTranslation()(FormNameValueItem);

FormNameValueItem.propTypes = {
    useColor: PropTypes.bool,
    useTypes: PropTypes.bool
};