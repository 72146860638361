import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Modal, Button, Result, } from "antd";
import { SaveOutlined, EditOutlined, DeleteOutlined, PlusOutlined, EyeOutlined, CloseCircleOutlined, LayoutOutlined, } from '@ant-design/icons';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import ReactHtmlParser from 'react-html-parser';
import { getFroalaConfigurationByDescriptionLayout } from './froalaConfigByDescriptionLayout';
import { isEmptyObject, isUndefined } from '../../../utils/JsObjectHelper';
import DocumentViewer from './DocumentViewer';
import CustomButtonsForDescription from './CustomButtonsForDescription';
const ResponsiveGridLayout = WidthProvider(Responsive);

class DecriptionLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layout: [],
            counter: 0,
            editorContents: {},
            isEditing: {},
            isEditingMode: false,
            visibleModal: false,
            viewDoc: null,
            selectedItem: null,
            editorRefs: [],
            descriptionHtml: null
        };
        this.buttonRef = React.createRef();
    }
    componentDidMount() {
        let description = this.props.description ? this.props.description : this.props.entryType && this.props.entryType.description ? JSON.parse(this.props.entryType.description) : null;
        let mode = this.props.mode === "template" ? 'DocumentLayoutTemplateMain' : 'id="DocumentLayoutMain"';
        if (description && description.includes(mode)) {
            const { layout, editorContents, editorRefs } = this.parseHtmlToLayoutAndContents(description);
            this.setState({ layout: layout, editorContents: editorContents, editorRefs: editorRefs, counter: layout.length });
        }

        this.initMutationObserver();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.description !== this.props.description || this.state.layout.length === 0) {
            if (this.props.mode === "entry" && this.props.description && this.props.description.includes('id="DocumentLayoutMain"')) {
                const { layout, editorContents, editorRefs } = this.parseHtmlToLayoutAndContents(this.props.description);
                this.setState({ layout: layout, editorContents: editorContents, editorRefs: editorRefs, counter: layout.length });
            }
        }
        if (prevState.isEditingMode !== this.state.isEditingMode && this.state.isEditingMode === true) {
            this.moveFroalaToolbarToPanel();
        }

    }
    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
    initMutationObserver() {
        const config = { attributes: false, childList: true, subtree: true };
        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    mutation.addedNodes.forEach(node => {
                        if (node.classList && node.classList.contains('fr-toolbar')) {
                            this.addMyButtons(node);
                        }
                    });
                }
            }
        };

        this.observer = new MutationObserver(callback);
        const targetNode = document.body;
        this.observer.observe(targetNode, config);
    }
    moveFroalaToolbarToPanel = () => {
        setTimeout(() => {
            const mainFroalaEditor = document.querySelector('.mainFroala');
            if (mainFroalaEditor) {
                const frBox = mainFroalaEditor.querySelector('.fr-box');
                if (frBox) {
                    const froalaToolbar = frBox.querySelector('.fr-toolbar');

                    if (froalaToolbar) {
                        const myToolPanel = document.querySelector('.toolPanel');
                        if (myToolPanel) {
                            myToolPanel.style.borderRadius = '0px';
                            myToolPanel.appendChild(froalaToolbar);
                            froalaToolbar.style.display = 'block';
                        }
                    }
                }
            }
        }, 100);
    }

    parseHtmlToLayoutAndContents = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const gridItems = this.props.mode === "template" ? doc.querySelectorAll('#DocumentLayoutTemplateMain > div') : doc.querySelectorAll('#DocumentLayoutMain > div');
        const layout = [];
        const editorContents = {};
        const editorRefs = {};
        gridItems.forEach((item, index) => {
            const style = item.getAttribute('style');
            const contentHtml = item.innerHTML;
            const gridColumnStart = style.match(/grid-column-start: (\d+)/)[1];
            const gridColumnEnd = style.match(/grid-column-end: span (\d+)/)[1];
            const gridRowStart = style.match(/grid-row-start: (\d+)/)[1];
            const gridRowEnd = style.match(/grid-row-end: span (\d+)/)[1];

            const x = parseInt(gridColumnStart) - 1;
            const y = parseInt(gridRowStart) - 1;
            const w = parseInt(gridColumnEnd);
            const h = parseInt(gridRowEnd);

            layout.push({
                i: String(index),
                x,
                y,
                w,
                h,
                moved: false,
                static: false
            });

            editorContents[String(index)] = contentHtml;
            editorRefs[String(index)] = React.createRef();
        });
        return { layout, editorContents, editorRefs };
    }
    onLayoutChange = (layout) => {
        this.setState({ layout });
    };

    addItem = () => {
        const newItemId = `${this.state.counter}`;
        const newItem = {
            i: newItemId,
            x: 0,
            y: Infinity,
            w: 24,
            h: 1,
            static: false
        };

        this.setState(prevState => ({
            layout: [...prevState.layout, newItem],
            counter: prevState.counter + 1,
            editorRefs: {
                ...prevState.editorRefs,
                [newItemId]: React.createRef()
            }
        }));
    };

    removeItem = (itemId) => {
        if (itemId === this.state.selectedItem) {
            this.setState(prevState => ({
                isEditing: false,
                isEditingMode: false,
                selectedItem: null,
                layout: prevState.layout.filter(item => item.i !== itemId)
            }));
        }
        else {
            this.setState(prevState => ({
                layout: prevState.layout.filter(item => item.i !== itemId)
            }));
        }

    };

    onModelChange = (itemId, model) => {
        this.setState(prevState => ({
            editorContents: {
                ...prevState.editorContents,
                [itemId]: model
            }
        }));
    };
    toggleBlockEditMode = (itemId) => {
        this.setState(prevState => {
            const isCurrentlyEditing = prevState.isEditing[itemId];
            const newIsEditing = {
                ...prevState.isEditing,
                [itemId]: !isCurrentlyEditing
            };

            const newSelectedItem = isCurrentlyEditing ? null : prevState.selectedItem;

            return {
                isEditing: newIsEditing,
                isEditingMode: Object.values(newIsEditing).some(editing => editing),
                selectedItem: newSelectedItem
            };
        });
    }

    handleFroalaContentChange = (itemId, model) => {
        this.onModelChange(itemId, model);

        const editorRef = this.state.editorRefs[itemId];
        if (editorRef && editorRef.current) {
            const contentHeight = editorRef.current.offsetHeight;

            const gridRowHeight = 30;
            const requiredHeight = Math.ceil(contentHeight / gridRowHeight);

            const currentItem = this.state.layout.find(item => item.i === itemId);
            if (requiredHeight > currentItem.h) {
                this.updateBlockSize(itemId, { h: requiredHeight, w: currentItem.w });
            }
        }
    };

    updateBlockSize = (itemId, newSize) => {
        this.setState(prevState => {
            const newLayout = prevState.layout.map(item => {
                if (item.i === itemId) {
                    return { ...item, h: newSize.h, w: newSize.w };
                }
                return item;
            });

            return { layout: newLayout };
        });
    };

    showModal = () => {
        this.setState({ visibleModal: true });
    };

    handleCancel = () => {
        if (this.state.isEditingMode) {
            return
        }
        if(this.props.mode === "template"){
            this.setState({
                visibleModal: false,
                viewDoc: null,
                selectedItem: null,
                isEditingMode: false,
            });
        }else{
            this.setState({
                visibleModal: false,
                viewDoc: null,
                selectedItem: null,
                layout: [],
                isEditingMode: false,
                editorContents: {},
                editorRefs: [],
                counter: 0
            });
        }
        
    };
    selectItem = (index) => {
        if (!this.state.isEditingMode) {
            this.setState({ selectedItem: index });
        }

    };
    handleEditorBlur = () => {
        const activeEditorRef = this.state.editorRefs[this.state.selectedItem];
        if (activeEditorRef && activeEditorRef.current) {
            const editor = activeEditorRef.current.editor;
            if (editor) {
                editor.selection.save();
            }
        }
    };
    handleSelectChange = (value) => {
        const activeEditorRef = this.state.editorRefs[this.state.selectedItem];
        if (activeEditorRef && activeEditorRef.current) {
            const editor = activeEditorRef.current.editor;
            if (editor) {
                this.setState({ popoverAtt: false, popoverRel: false });
                editor.events.focus(true);
                editor.selection.restore();
                editor.html.insert(value);
            }
        }
    };


    addMyButtons = () => {
        const myToolPanel = document.querySelector('.toolPanel');
        if (myToolPanel) {
            const targetElement = myToolPanel.querySelector('.fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off .fr-btn-grp.fr-float-right');
            const buttonCopy = this.buttonRef.current;

            if (targetElement && targetElement.parentNode && buttonCopy) {
                if (targetElement.nextSibling !== buttonCopy) {
                    targetElement.parentNode.insertBefore(buttonCopy, targetElement.nextSibling);
                    targetElement.style.marginLeft = '60px';
                    buttonCopy.style.display = 'block';
                }
            }
        }

    };

    parseHtml = () => {
        const { layout, editorContents } = this.state;
        const columnCount = 12;
        const gapSize = 10;
        const columnWidth = (1240 - (columnCount - 1) * gapSize) / columnCount;
        const gridItems = this.props.mode === "template" ? "DocumentLayoutTemplateMain" : "DocumentLayoutMain";
        let htmlContent = layout.map(item => {
            const contentHtml = editorContents[item.i] || '';
            return `
            <div style="grid-column-start: ${item.x + 1}; grid-column-end: span ${item.w}; grid-row-start: ${item.y + 1}; grid-row-end: span ${item.h}; overflow:hidden; padding:5px; height: ${item.h * 150}px;">
                ${contentHtml}
            </div>
        `;
        }).join('');

        let viewDocNew = `<div class="fr-view" style="display: grid; grid-template-columns: repeat(${columnCount}, ${columnWidth}px); grid-gap: ${gapSize}px; grid-auto-rows: minmax(150px, auto); position: relative;  justify-content: center;" id="${gridItems}">
        ${htmlContent}
    </div>`;
        return viewDocNew
    };
    handleOk = () => {
        let htmlLayout = this.parseHtml();
        this.props.setDescription(JSON.stringify(htmlLayout));
        if(this.props.mode === "template"){
            this.setState({
                visibleModal: false,
                viewDoc: null,
                selectedItem: null,
                isEditingMode: false,
            });
        }else{
            this.setState({
                visibleModal: false,
                viewDoc: null,
                selectedItem: null,
                layout: [],
                editorContents: {},
                editorRefs: [],
                counter: 0,
                isEditingMode: false,
            });
        }
    }
    getRelationName = (sourceType, attType) => {
        let entryDef = this.props.objectListRequestResult.getData().find(eT => eT.type === sourceType);
        if (!isEmptyObject(entryDef) && !isUndefined(entryDef.properties.attributes)) {
            let attDef = entryDef.properties.attributes.find(att => att.techName === attType);
            return (!isEmptyObject(attDef) && !isEmptyObject(attDef.name)) ? attDef.name : attType;
        }
        return attType;
    };
    viewDocument = () => {
        let htmlLayout = this.parseHtml();
        this.setState({ viewDoc: !this.state.viewDoc, selectedItem: null, descriptionHtml: htmlLayout })
    }

    render() {
        const { t } = this.props;
        const { layout, isEditingMode, isEditing, viewDoc, descriptionHtml } = this.state;
        let layoutSize = { lg: layout }

        let froalaConfig = getFroalaConfigurationByDescriptionLayout(t)
        froalaConfig.events = {
            'blur': this.handleEditorBlur
        }

        let myButtons = <CustomButtonsForDescription
            triger="hover"
            t={t}
            entryType={this.props.entryType}
            incomingRelationsHolder={this.props.incomingRelationsHolder}
            buttonRef={this.buttonRef}
            objectListRequestResult={this.props.objectListRequestResult}
            handleSelectChange={this.handleSelectChange}
        />
        let main = <ResponsiveGridLayout
            isDraggable={!isEditingMode}
            isResizable={!isEditingMode}
            margin={[10, 10,]}
            containerPadding={[12, 12]}
            className={"layoutDescription"}
            layouts={layoutSize}
            draggableCancel=".froala-editor-container, .froala-editor-container *"
            onLayoutChange={this.onLayoutChange}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        >
            {layout.map(item => (
                <div key={item.i} onClick={() => this.selectItem(item.i)} style={this.state.selectedItem === item.i ? { border: "3px dashed rgb(78, 78, 78)" } : null}>
                    {isEditing[item.i] ?
                        <div className="froala-editor-container mainFroala">
                            <FroalaEditor
                                key={item.i}
                                className="froala-editor-custom"
                                ref={this.state.editorRefs[item.i]}
                                model={this.state.editorContents[item.i]}
                                onModelChange={model => this.handleFroalaContentChange(item.i, model)}
                                config={{
                                    ...froalaConfig,
                                    height: item.h * 150 - 5,
                                    toolbarInline: false,
                                    toolbarVisibleWithoutSelection: true,

                                }}
                            />
                        </div>
                        :
                        <div className="fr-box fr-basic fr-top" >
                            <div className="fr-element fr-view" style={{ padding: '0px' }}>
                                {ReactHtmlParser(this.state.editorContents[item.i])}
                            </div>
                        </div>
                    }
                </div>
            ))}
        </ResponsiveGridLayout>
        if (layout.length === 0 && !viewDoc) {
            main = <Result
                title={this.props.mode === "template" ?t('app.entry.descriptionLayout.alertForAdmin'):t('app.entry.descriptionLayout.alertForUser')}
            />
        }
        if (viewDoc) {
            main = <div className="fr-box fr-basic fr-top layoutDescription" >
                <DocumentViewer descriptionHtml={descriptionHtml} />
            </div>
        }
        let modal = <Modal title={t('app.entry.descriptionLayout.modalTitle')} visible={this.state.visibleModal} destroyOnClose={true} maskClosable={false} className='modalEditDescription' width={'1300px'}
            okButtonProps={{ disabled: this.state.isEditingMode || layout.length === 0 }}
            cancelButtonProps={{ disabled: this.state.isEditingMode }} onOk={this.handleOk} onCancel={this.handleCancel}>
            <div className='toolPanel' style={{ padding: isEditingMode ? '0px' : '8px 10px 8px 0px' }}>
                {this.state.selectedItem ? <>
                    <Button style={{ display: isEditingMode ? 'none' : 'inline-block', color: 'black' }} type='link' onClick={() => this.toggleBlockEditMode(this.state.selectedItem)}> <span><EditOutlined />{t('app.entry.descriptionLayout.btnEdit')}</span></Button>
                    <Button className="remove" type='link' style={{ display: isEditingMode ? 'none' : 'inline-block', color: 'black' }} onClick={() => this.removeItem(this.state.selectedItem)}>
                        <span><DeleteOutlined />{t('app.entry.descriptionLayout.btnDelete')}</span>
                    </Button>
                    <Button className='saveButton' type='link' style={{ display: isEditingMode ? 'inline-block' : 'none', color: 'black' }} onClick={() => this.toggleBlockEditMode(this.state.selectedItem)}><SaveOutlined />{t('app.entry.descriptionLayout.btnSave')}</Button>
                </> : null}
                <Button onClick={this.viewDocument} type='link' style={{ display: isEditingMode ? 'none' : 'inline-block', color: 'black' }} >{viewDoc ? <span><CloseCircleOutlined /> {t('app.entry.descriptionLayout.btnCancel')}</span> : <span><EyeOutlined />{t('app.entry.descriptionLayout.btnViewDocument')}</span>}</Button>
                <Button onClick={this.addItem} type='link' disabled={viewDoc} style={{ display: isEditingMode ? 'none' : 'inline-block', color: 'black' }} ><span><PlusOutlined />{t('app.entry.descriptionLayout.btnAddElement')} </span></Button>

            </div>
            <div style={{ position: 'relative', padding: '0px,20px,20px,20px', }}>

                {main}

            </div>
        </Modal>
        return (
            <div style={{ textAlign: 'center' }}>
                <Button type="link" style={{ padding: '0px' }} onClick={this.showModal}>{this.props.mode === "entry" ? <LayoutOutlined style={{ fontSize: '18px', color: 'black' }} /> : <span style={{ fontSize: '16px', color: 'black', fontWeight: 'bold' }}><LayoutOutlined />  {t('app.entry.descriptionLayout.btnTemplateForDescription')}</span>}</Button>
                {modal}
                {myButtons}

            </div>
        );
    }
}

export default DecriptionLayout;