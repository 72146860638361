import React from 'react';
import {Select, Button, Tag, Tooltip} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import 'antd/dist/antd.css';
import {withRouter} from "react-router-dom";
import {fetchEntries} from '../../apicalls/fetchEntries';
import debounce from 'lodash/debounce';
import { withTranslation} from 'react-i18next'
import { isEmptyValue } from '../../utils/JsObjectHelper';
import { getTextColorFromBackgroundHex } from '../../utils/ColorHelper';
import { fetchEntryDetailMinimal } from '../../apicalls/fetchEntryDetail';

const Option = Select.Option;

class SearchHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: [],
            searchedData: [],
            searchedMillis: 0,
            value: undefined,
            initValue: null,
            types: [],
            lastSearchLocation: undefined,
            lastPropsLocation: props.location.pathname,
            tooltipParentName : null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.createSelectOption = this.createSelectOption.bind(this);
        this.handleAdvancedSearch = this.handleAdvancedSearch.bind(this);
        this.handleSearch = debounce(this.handleSearch, 500);
    }

    handleSearch = (value) => {
        this.setState({initValue:value})
        fetchEntries(value,"searchHeader", data => this.setState({ data }),null,null,null,null,30);
    };
    setLocalSearchValue = () =>{
        if(!isEmptyValue(this.state.initValue)){
            localStorage.setItem('searchHeader', this.state.initValue);
        }
    };
    handleChange = (value) => {
        this.setState({ value });
        this.setState({ 
               lastSearchLocation : `/entry/${value}`,
               lastPropsLocation: this.props.location.pathname,
            }, () => { this.props.history.push(`/entry/${value}`); });
    };

    handleAdvancedSearch = (e) => {
        this.props.history.push(`/search`)
    };

    toolTipVisibleChanged = (visible, parent) => {
        if (visible) {
            fetchEntryDetailMinimal(parent, (data) => this.setState({tooltipParentName : data.name}));
        } else {
            this.setState({ tooltipParentName : null});
        }
    };

    createSelectOption = (foundEntry) => {
        const {t} = this.props;
        let entryTypeName = foundEntry.type;
        let entryTypeColor = "blue";
        let tagTextColor = "#000000";
        if (this.props.entryTypesRequestResult.getState().isDone()) {
            let eType = this.props.entryTypesRequestResult.getData().find(eTypes => { return eTypes.type === foundEntry.type }) 
            entryTypeName = eType.name;
            if (!isEmptyValue(eType.properties.typeColor)) {
                entryTypeColor = eType.properties.typeColor;
                tagTextColor = getTextColorFromBackgroundHex(eType.properties.typeColor);
            }
        }

        let tooltipText = t('app.entry.header.msgLoading');

        if (!isEmptyValue(this.state.tooltipParentName)) {
            tooltipText = t('app.header.searchBoxParentFolderName') + this.state.tooltipParentName;
        }

        return (
            <Option key={foundEntry.id} /*title={foundEntry.name}*/ style={{width:'100%'}}>
                <Tooltip placement="bottom" onVisibleChange={(visible) => this.toolTipVisibleChanged(visible, foundEntry.parent)} title={tooltipText}>
                    <div>
                    <span>{foundEntry.name}</span>
                    <Tag color={(foundEntry.systemType==="folder"?"#ffc069":entryTypeColor)} style={{float:'right', color:tagTextColor}} className="searchBoxEntryTypeTag">{entryTypeName}</Tag>
                    </div>
                </Tooltip>
            </Option>
        );
    };

    /**
     * UNSAFE and deprecated lifecycle method!
     */
    /*UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
console.log('SEARCH HANDLER PROPS CHANGE');
        if (nextProps.location !== this.props.location &&
            this.state.lastSearchLocation !== undefined &&
            nextProps.location.pathname !== this.state.lastSearchLocation) {
console.log('SEARCH HANDLER RESETING STATE');
            this.setState({ value : undefined });
            this.setState({ data : [] });
        }
    }*/

    /**
     * New way of props to state change from React 17
     * @param {*} props 
     * @param {*} state 
     */
    static getDerivedStateFromProps(props, state) {
        if (state.lastPropsLocation !== props.location.pathname &&
            state.lastSearchLocation !== undefined && 
            props.location.pathname !== state.lastSearchLocation) {
            return {
                value: undefined,
                data : [],
                lastSearchLocation: undefined,
                lastPropsLocation: props.location.pathname,
            };
        }
        // Return null to indicate no change to state.
        return null;
    }

    render() {
        const {t} = this.props;
        const options = this.state.data.map(d => this.createSelectOption(d));

        return <div>
            <SearchOutlined style={{ paddingRight : 10 }}/>
            <Select
                showSearch={true}
                value={this.state.value}
                placeholder={t('app.header.searchBoxHint')}
                // style={{ width: 400 }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}
                className="mainSearchBox"
            >
                {options}
            </Select>
            <Link to={`/search`} style={{ paddingLeft : 10 }}><Button onClick={this.setLocalSearchValue}>{t('app.header.searchBtnText')}</Button></Link>
        </div>;
    }
}


const SearchHeaderWithRouter = withRouter(SearchHeader);

export default withTranslation() (SearchHeaderWithRouter);