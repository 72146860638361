import {fetchJson} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";


/**
 * 
 * @param {String} entryType 
 * @param {Function<Array[Object]>} callback 
 */
export const getEntryTypeSetup = (entryType, callback) => {
    let json = fetchJson(`${config.url.API_URL}/objectDefinitions/search/findByType?type=${entryType}`);
    json.then(response => {
        if (callback) {
            if (response) {
                callback(response);
            } else {
                callback(null);
            }
        } else {
            return response;
        }
    }).catch(error => {
        console.error(error);
        if (callback) {
            callback(null);
        } else {
            return null;
        }
    });
};