import header from './header/header.json';
import advancedSearch from './search/advancedSearch.json';
import bpmn from './bpmn/bpmn.json';
import userDashboard from './userDashboard/userDashboard.json';
import userPage from './userPage/userPage.json';
import entry from './entry/index.js';
import lineage from './lineage/lineage.json';
import setup from './setup/index.js';
let languageCS = {
    "translations": {
        "datetime": "{{date, LLL}}",
        "datetimeFormat": "DD. MM. YYYY HH:mm:ss",
        "dateFormat": "DD. MM. YYYY",
        "dateFromNow": "{{date, FROM_NOW}}",
        "date": "{{date, l}}",
        "number": "{{number}}",
        "app": {
            "defaultTable": {
                "filterTitle": "Filtr",
                "filterConfirm": "OK",
                "filterReset": "Smazat dotaz",
                "emptyText": "Nenalezeno"
            },
            "genericControls": {
                "copyValue2ClipboardButton": {
                    "hint": "Zkopíruje hodnotu do clipboardu"
                },
                "formNameValueItem": {
                    "tabName": "Název",
                    "tabValue": "Hodnota",
                    "tabColor": "Barva",
                    "show": "Zobrazit",
                    "hide": "Skrýt" 
                },
                "formNameValueManipulationModal": {
                    "btnAddItem": "Přidat dvojici Název-Hodnota",
                    "btnUpdateItem": "Upravit dvojici Název-Hodnota",
                    "titleAddItem": "Editace dvojic Název-Hodnota",
                    "lblAddName": "Vyplňte Název",
                    "lblAddValue": "Vyplňte Hodnotu",
                    "lblValueType": "Typ Hodnoty",
                    "lblValueTypeString": "Text",
                    "lblValueTypeNumber": "Číslo",
                    "lblValueTypeBoolean": "Boolean",
                    "msgAddName": "Vyplňte prosím Název",
                    "msgAddValue": "Vyplňte prosím Hodnotu",
                    "lblAddColor": "Vyberte barvu"
                }
            },
            "colorPicker": {
                "lblColorPreview": "náhled barvy",
                "boxColorHint": "Vyberte předdefinovanou barvu, nebo zadejte hexadecimální kód barvy",
                "btnPickColor": "Vybrat barvu",
                "modalTitle": "Paleta barev",
                "modalBtnReturn": "Potvrdit",
                "customColor": "Vlastní barva:"
            },
            "iconPicker": {
                "modalTitle": "Paleta ikon"
            },
            ...header,
            ...advancedSearch,
            ...bpmn,
            ...userDashboard,
            ...userPage,
            ...entry,
            ...lineage
        },
        ...setup
    }
}

export default languageCS;
