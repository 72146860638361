import { Badge, Button, Checkbox, DatePicker, Input, InputNumber, Select, Tooltip, Alert } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import React from "react";
import { withTranslation } from 'react-i18next'
import { isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import AdvancedSearchOperand from './AdvancedSearchOperand';
import PropTypes, { object } from 'prop-types';
import moment from "moment";
import { isMobile, isTablet } from "../../utils/AdaptiveLayout";

const { Option } = Select;

class AdvancedSearchCondition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            selectedAttribute: null,
            visible: false,
            conditionEmpty: false
        };
    }

    handleOperandChange = (op) => {
        this.props.onOperandChange(this.props.dataIndex, op)
    };

    handleAttributeChange = (att) => {
        let conditionType = null;
        let value1 = null;
        let value2 = null;

        let attDefinition = this.props.entryTypeAttributes.find(a => a.techName === att);

        if (!isUndefined(attDefinition)) {
            switch (attDefinition.type) {
                case "string":
                    conditionType = "containsword";
                    break;
                case "number":
                    conditionType = "=";
                    if (!isUndefined(attDefinition.minLength)) {
                        value1 = parseFloat(attDefinition.minLength);
                    }
                    if (!isUndefined(attDefinition.maxLength)) {
                        value2 = parseFloat(attDefinition.maxLength);
                    }
                    break;
                case "date":
                    conditionType = "=";
                    break;
                case "boolean":
                    conditionType = "=";
                    value1 = false;
                    break;
                case "lov":
                    conditionType = "containsword";
                    break;
                default:
                    break;
            }
        }

        this.props.onConditionChanged(
            this.props.dataIndex,
            {
                attribute: att,
                conditionType: conditionType,
                value1: value1,
                value2: value2
            }
        );
    };

    handleConditionTypeChange = (conType) => {
        if (conType === "containsword" || conType === "notcontainsword") {
            if (/\s/g.test(this.props.queryData.value1)) {
                this.setState({ visible: true })
            }
            else {
                this.setState({ visible: false })
            }
        }
        else {
            this.setState({ visible: false })
        };

        if (conType === 'empty' || conType === "notempty") {
            this.setState({ conditionEmpty: true })
        }
        else {
            this.setState({ conditionEmpty: false })
        };

        this.props.onConditionChanged(
            this.props.dataIndex,
            {
                attribute: this.props.queryData.attribute,
                conditionType: conType,
                value1: this.props.queryData.value1,
                value2: this.props.queryData.value2
            }
        );
    };

    handleValueBooleanChange = (evnt) => {
        this.props.onConditionChanged(
            this.props.dataIndex,
            {
                attribute: this.props.queryData.attribute,
                conditionType: "=",
                value1: evnt.target.checked,
                value2: null
            }
        );
    };

    handleValueStringChange = ({ target: { value } }) => {
        if (this.props.queryData.conditionType === "containsword" || this.props.queryData.conditionType === "notcontainsword") {
            if (/\s/g.test(value)) {
                this.setState({ visible: true })
            }
            else {
                this.setState({ visible: false })
            }
        }
        this.props.onConditionChanged(
            this.props.dataIndex,
            {
                attribute: this.props.queryData.attribute,
                conditionType: this.props.queryData.conditionType,
                value1: value,
                value2: null
            }
        );
    };

    handleValueNumberChange = (value1, value2) => {
        if (isUndefined(value1)) {
            value1 = this.props.queryData.value1;
        }
        if (isUndefined(value2)) {
            value2 = this.props.queryData.value2;
        }

        this.props.onConditionChanged(
            this.props.dataIndex,
            {
                attribute: this.props.queryData.attribute,
                conditionType: this.props.queryData.conditionType,
                value1: value1,
                value2: value2
            }
        );
    };

    handleValueDateChange = (value1, value2) => {
        if (isUndefined(value1)) {
            value1 = this.props.queryData.value1;
        } else {
            value1 = value1.utc().format();//.toDate().toUTCString();
        }
        if (isUndefined(value2)) {
            value2 = this.props.queryData.value2;
        } else {
            value2 = value2.utc().format();//.toDate().toUTCString();
        }

        this.props.onConditionChanged(
            this.props.dataIndex,
            {
                attribute: this.props.queryData.attribute,
                conditionType: this.props.queryData.conditionType,
                value1: value1,
                value2: value2
            }
        );
    };

    handleRemove = () => {
        this.props.onRemoveCondition(this.props.dataIndex);
    };
    handleLovTypeChange = (value) => {
        this.props.onConditionChanged(
            this.props.dataIndex,
            {
                attribute: this.props.queryData.attribute,
                conditionType: "=",
                value1: value,
                value2: null
            }
        );
    }
    selectLanguage = (value) => {
        this.props.onConditionChanged(
            this.props.dataIndex,
            {
                attribute: this.props.queryData.attribute + "_lang_" + value,
                conditionType: "contains",
                value1: null,
                value2: null
            }
        );
    }
    handleValueLocStringChange = ({ target: { value } }) => {
        this.props.onConditionChanged(
            this.props.dataIndex,
            {
                attribute: this.props.queryData.attribute,
                conditionType: this.props.queryData.conditionType,
                value1: value,
                value2: null
            }
        );
    }
    render() {
        const { t, i18n } = this.props;

        let attributeOptions = this.props.entryTypeAttributes.map(at => <Option key={at.techName} value={at.techName}>{at.name}</Option>);

        let attributeSearchOptions = null;

        let conditionStatus = "error";

        if (!isUndefined(this.props.queryData.attribute)) {

            let attDefinition = this.props.entryTypeAttributes.find(att => {
                let attribute = this.props.queryData.attribute.split('_lang_')[0];
                return att.techName === attribute
            });
            if (!isUndefined(attDefinition)) {
                switch (attDefinition.type) {
                    case "string":
                        if (!isEmptyValue(this.props.queryData.attribute) &&
                            !isEmptyValue(this.props.queryData.conditionType) &&
                            (!isEmptyValue(this.props.queryData.value1) || this.props.queryData.conditionType === "empty" || this.props.queryData.conditionType === "notempty"))
                            conditionStatus = "success";
                        attributeSearchOptions = [
                            <Select key="conditionType" size="small" style={{ width: 160, marginRight: '5px', marginBottom: "10px" }}
                                placeholder={t('app.advancedSearch.conditionTypeHint')}
                                value={this.props.queryData.conditionType}
                                onChange={this.handleConditionTypeChange}
                            >
                                <Option value="containsword">{t('app.advancedSearch.conditionContainsword')}</Option>
                                <Option value="notcontainsword">{t('app.advancedSearch.conditionNotcontainsword')}</Option>
                                <Option value="containsphrase">{t('app.advancedSearch.conditionContainsphrase')}</Option>
                                <Option value="notcontainsphrase">{t('app.advancedSearch.conditionNotcontainsphrase')}</Option>
                                {/*<Option value="contains">{t('app.advancedSearch.conditionContains')}</Option>
                                <Option value="notcontains">{t('app.advancedSearch.conditionNotcontains')}</Option>
                                <Option value="starts">{t('app.advancedSearch.conditionStartswith')}</Option>
                                <Option value="ends">{t('app.advancedSearch.conditionEndswith')}</Option> */}
                                <Option value="empty">{t('app.advancedSearch.conditionEmpty')}</Option>
                                <Option value="notempty">{t('app.advancedSearch.conditionNotempty')}</Option>
                            </Select>,
                            <Tooltip placement="right" title={t('app.advancedSearch.validateMesageInputSpace')} visible={isMobile() ? false : this.state.visible} color={"red"} key={"red"}>
                                <Input key="value1" size="small" allowClear={true}
                                    value={this.props.queryData.value1}
                                    placeholder={t('app.advancedSearch.conditionFillValueHint')}
                                    onChange={this.handleValueStringChange}
                                    style={{ width: 200 }}
                                    disabled={this.state.conditionEmpty}
                                >
                                </Input>
                            </Tooltip>

                        ];
                        break;
                    case "number":
                        if (!isEmptyValue(this.props.queryData.attribute) &&
                            !isEmptyValue(this.props.queryData.conditionType) &&
                            !isEmptyValue(this.props.queryData.value1) &&
                            (this.props.queryData.conditionType !== "between" || !isEmptyValue(this.props.queryData.value2)))
                            conditionStatus = "success";
                        let minVal = null;
                        let maxVal = null;
                        if (!isUndefined(attDefinition.minLength)) {
                            minVal = parseFloat(attDefinition.minLength);
                        }
                        if (!isUndefined(attDefinition.maxLength)) {
                            maxVal = parseFloat(attDefinition.maxLength);
                        }
                        attributeSearchOptions = [
                            <Select key="conditionType" size="small" style={{ width: 130, marginRight: '5px' }}
                                placeholder={t('app.advancedSearch.conditionTypeHint')}
                                value={this.props.queryData.conditionType}
                                onChange={this.handleConditionTypeChange}
                            >
                                <Option value="=">{"="}</Option>
                                <Option value="<">{"<"}</Option>
                                <Option value=">">{">"}</Option>
                                <Option value="<=">{"<="}</Option>
                                <Option value=">=">{">="}</Option>
                                <Option value="empty">{t('app.advancedSearch.conditionEmpty')}</Option>
                                <Option value="notempty">{t('app.advancedSearch.conditionNotempty')}</Option>
                                <Option value="between">{t('app.advancedSearch.conditionBetween')}</Option>
                            </Select>
                            ,
                            <InputNumber key="value1" size="small"
                                min={minVal} max={maxVal}
                                value={this.props.queryData.value1}
                                decimalSeparator={(1.1).toLocaleString(i18n.language).replace(/1/g, '')}
                                onChange={(v) => this.handleValueNumberChange(v, null)}
                                disabled={this.props.queryData.conditionType === "empty" || this.props.queryData.conditionType === "notempty"} />,
                            <span key="separator"> - </span>,
                            <InputNumber key="value2" size="small"
                                min={minVal} max={maxVal}
                                value={this.props.queryData.value2}
                                decimalSeparator={(1.1).toLocaleString(i18n.language).replace(/1/g, '')}
                                onChange={(v) => this.handleValueNumberChange(null, v)}
                                disabled={this.props.queryData.conditionType !== "between"} />
                        ];
                        break;
                    case "date":
                        if (!isEmptyValue(this.props.queryData.attribute) &&
                            !isEmptyValue(this.props.queryData.conditionType) &&
                            !isEmptyValue(this.props.queryData.value1) &&
                            (this.props.queryData.conditionType !== "between" || !isEmptyValue(this.props.queryData.value2)))
                            conditionStatus = "success";
                        const timeOpts = { allowClear: true, defaultValue: moment("00:00:00.0", "HH:mm:ss.S") };
                        let dateValue1 = null;
                        let dateValue2 = null;
                        if (!isEmptyValue(this.props.queryData.value1)) {
                            dateValue1 = moment(this.props.queryData.value1);
                            if (!dateValue1.isValid()) {
                                dateValue1 = null;
                            }
                        }
                        if (!isEmptyValue(this.props.queryData.value2)) {
                            dateValue2 = moment(this.props.queryData.value2);
                            if (!dateValue2.isValid()) {
                                dateValue2 = null;
                            }
                        }
                        attributeSearchOptions = [
                            <Select key="conditionType" size="small" style={{ width: 100, marginRight: '5px' }}
                                placeholder={t('app.advancedSearch.conditionTypeHint')}
                                value={this.props.queryData.conditionType}
                                onChange={this.handleConditionTypeChange}
                            >
                                <Option value="=">{"="}</Option>
                                <Option value="<">{"<"}</Option>
                                <Option value=">">{">"}</Option>
                                <Option value="<=">{"<="}</Option>
                                <Option value=">=">{">="}</Option>
                                <Option value="between">{t('app.advancedSearch.conditionBetween')}</Option>
                            </Select>
                            ,
                            <DatePicker key="value1" size="small"
                                value={dateValue1}
                                format={t("dateFormat")}
                                showTime={timeOpts}
                                onChange={(v) => this.handleValueDateChange(v, null)} />,

                            <span key="separator"> - </span>,
                            <DatePicker key="value2" size="small"
                                value={dateValue2}
                                format={t("dateFormat")}
                                showTime={timeOpts}
                                onChange={(v) => this.handleValueDateChange(null, v)}
                                disabled={this.props.queryData.conditionType !== "between"} />
                        ];
                        break;
                    case "boolean":
                        if (!isEmptyValue(this.props.queryData.attribute))
                            conditionStatus = "success";
                        attributeSearchOptions = <Checkbox
                            onChange={this.handleValueBooleanChange}
                            checked={this.props.queryData.value1}
                            style={{ marginLeft: '10px' }}
                        >
                        </Checkbox>;
                        break;

                    case "lov":
                        if (!isEmptyValue(this.props.queryData.attribute))
                            conditionStatus = "success";
                        attributeSearchOptions = <Select key="lov" size="small" style={{ maxWidth: 575, marginRight: '5px' }}
                            placeholder={t('app.advancedSearch.lovPlaceholder')}
                            value={this.props.queryData.value1}
                            onChange={this.handleLovTypeChange}
                        >
                            {attDefinition.lovArray.map(at => <Option key={at.value} value={at.value}>{at.name}</Option>)}
                        </Select>

                        break;
                    case "localizedString":
                        if (!isEmptyValue(this.props.queryData.attribute))
                            conditionStatus = "success";
                        let languages = attDefinition.selectedLanguages ? attDefinition.selectedLanguages : ["en"];
                        let attribute = this.props.queryData.attribute.split('_lang_');
                        let selectedLanguage = attribute.length > 1 ? attribute[attribute.length - 1] : null;
                        let selectBefore = <Select key="lang" size="small" style={{ width: 130, marginRight: '5px' }}
                            placeholder={t('app.advancedSearch.localizedStringSelectPlaceholder')}
                            onChange={this.selectLanguage}
                            value={selectedLanguage}
                        >
                            {languages.map(at => <Option key={at} value={at}>{at}</Option>)}
                        </Select>


                        attributeSearchOptions = <Input addonBefore={selectBefore}
                            placeholder={t('app.advancedSearch.localizedStringInputPlaceholder')}
                            onChange={this.handleValueLocStringChange}
                            value={this.props.queryData.value1}
                            disabled={attribute.length < 2}
                            style={{ maxWidth: 575, }}
                            size="small" />

                        break;
                    default:
                        break;
                }
            }
        }
        let selectedAtt = this.props.queryData.attribute ? this.props.queryData.attribute.split('_lang_')[0] : '';
        return <div>
            <AdvancedSearchOperand
                operand={this.props.operand}
                onOperandChange={this.handleOperandChange}
            >
            </AdvancedSearchOperand>
            <div>
                <Badge status={conditionStatus}></Badge>
                <Tooltip placement={isMobile() || isTablet() ? "top" : "left"} title={t('app.advancedSearch.selectAttributeHint')} visible={isUndefined(this.props.queryData.attribute)}>
                    <Select size="small" style={{ width: '90%', marginBottom: '5px' }} onChange={this.handleAttributeChange} value={selectedAtt}>
                        {attributeOptions}
                    </Select>
                </Tooltip>
                <div style={{ marginTop: '10px', marginLeft: '15px' }}>
                    {attributeSearchOptions}
                    <Button title={t('app.advancedSearch.btnRemoveCondition')}
                        onClick={this.handleRemove}
                        //disabled={isEmptyValue(this.props.operand)} 
                        icon={<DeleteOutlined />} type="danger" size="small" style={{ marginLeft: '15px' }}></Button>
                    {
                        isMobile() && this.state.visible ? <Alert
                            style={{ marginTop: '10px' }}
                            description={t('app.advancedSearch.validateMesageInputSpace')}
                            type="error"

                        /> : null
                    }
                </div>

            </div>
        </div>;
    }
}

AdvancedSearchCondition.propTypes = {
    queryData: PropTypes.object,
    dataIndex: PropTypes.number,
    entryTypeAttributes: PropTypes.arrayOf(object),
    onOperandChange: PropTypes.func.isRequired,
    onConditionChanged: PropTypes.func.isRequired,
    onRemoveCondition: PropTypes.func.isRequired
};

export default withTranslation()(AdvancedSearchCondition);