import menu from './menu.json';
import general from './general.json';
import entryTypes from './entryTypes.json';
import userManagment from './userManagment.json';
import userMapping from './userMapping.json';
import identity2entry from './identity2entry.json';
import lineageSetup from './lineageSetup.json';
import workflowSetup from './workflowSetup.json';
import bpmn from './bpmn.json';
import reindex from './reindex.json';
import loader from './loader.json';
import branding from './branding.json';
import backupRestore from './backupRestore.json';
export default {
    "setup": {
        ...menu,
        ...general,
        ...entryTypes,
        ...userManagment,
        ...userMapping,
        ...identity2entry,
        ...lineageSetup,
        ...workflowSetup,
        ...bpmn,
        ...reindex,
        ...loader,
        ...branding,
        ...backupRestore
    }
}