import { isEmptyObject, isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";

export const replaceAttributsInDescription = (entry, relation, incomingRelations, t) => {
    let newDesc = entry.description;
    if (!isEmptyValue(newDesc) && (entry.type !== "folder" || entry.type !== "home")) {
        if (relation && relation.data && relation.data.length > 0) {
            relation.data.forEach((el) => {
                const patterns = {
                    text: `{{\\(${el.name}\\|text\\)}}`,
                    tag: `{{\\(${el.name}\\|tag\\)}}`,
                    table: `{{\\(${el.name}\\|table\\)}}`,
                    ul: `{{\\(${el.name}\\|ul\\)}}`
                };
                const relatedElements = relation.data.filter(rel => el.name === rel.name);
                for (let type in patterns) {
                    let regex = new RegExp(patterns[type], 'g');
                    if (regex.test(newDesc)) {
                        switch (type) {
                            case 'tag':
                                let tagReplacements = relatedElements.map(rel => `<span class="ant-tag" style="background-color:rgb(230, 247, 255);"><a href="${rel.target.id}" target="_blank" >${rel.target.name}</a></span>`).join('');
                                newDesc = newDesc.replace(regex, tagReplacements);
                                break;
                            case 'text':
                                let textReplacements = relatedElements.map(rel => `<a href="${rel.target.id}" target="_blank">${rel.target.name}</a>`).join(', ');
                                newDesc = newDesc.replace(regex, textReplacements);
                                break;
                            case 'table':
                                let tableRows = relatedElements.map(rel => `<tr><td><a href="${rel.target.id}" target="_blank">${rel.target.name}</a></td></tr>`).join('');
                                newDesc = newDesc.replace(regex, `<table><tbody>${tableRows}</tbody></table>`);
                                break;
                            case 'ul':
                                let listItems = relatedElements.map(rel => `<li><a href="${rel.target.id}" target="_blank">${rel.target.name}</a></li>`).join('');
                                newDesc = newDesc.replace(regex, `<ul>${listItems}</ul>`);
                                break;
                        }
                    }
                }
            });
        }
        if (incomingRelations && incomingRelations.data && incomingRelations.data.length > 0) {
            incomingRelations.data.forEach((rel) => {
                const patterns = {
                    text: `{{\\{${rel.name}\\|text\\}}}`,
                    tag: `{{\\{${rel.name}\\|tag\\}}}`,
                    table: `{{\\{${rel.name}\\|table\\}}}`,
                    ul: `{{\\{${rel.name}\\|ul\\}}}`
                };
                const relatedElements = incomingRelations.data.filter(el => el.name === rel.name);
                for (let type in patterns) {
                    let regex = new RegExp(patterns[type], 'g');
                    if (regex.test(newDesc)) {
                        switch (type) {
                            case 'tag':
                                let tagReplacements = relatedElements.map(rel => `<span class="ant-tag" style="background-color:rgb(230, 247, 255);"><a href="${rel.source.id}" target="_blank" >${rel.source.name}</a></span>`).join('');
                                newDesc = newDesc.replace(regex, tagReplacements);
                                break;
                            case 'text':
                                let textReplacements = relatedElements.map(rel => `<a href="${rel.source.id}" target="_blank">${rel.source.name}</a>`).join(', ');
                                newDesc = newDesc.replace(regex, textReplacements);
                                break;
                            case 'table':
                                let tableRows = relatedElements.map(rel => `<tr><td><a href="${rel.source.id}" target="_blank">${rel.source.name}</a></td></tr>`).join('');
                                newDesc = newDesc.replace(regex, `<table><tbody>${tableRows}</tbody></table>`);
                                break;
                            case 'ul':
                                let listItems = relatedElements.map(rel => `<li><a href="${rel.source.id}" target="_blank">${rel.source.name}</a></li>`).join('');
                                newDesc = newDesc.replace(regex, `<ul>${listItems}</ul>`);
                                break;
                        }
                    }
                }
            })
        }
        if (!isEmptyObject(entry.properties)) {
            Object.keys(entry.properties).forEach(key => {
                const patterns = {
                    link: `{{\\[${key}\\|link\\]}}`,
                    date: `{{\\[${key}\\|date\\]}}`,
                };
                for (let type in patterns) {
                    let regex = new RegExp(patterns[type], 'g');
                    if (regex.test(newDesc)) {
                        switch (type) {
                            case 'link':
                                let linkReplacements = `<a href="${entry.properties[key].url}" target="_blank" >${entry.properties[key].label}</a>`;
                                newDesc = newDesc.replace(regex, linkReplacements);
                                break;
                            case 'date':
                                if (!isUndefined(entry.properties[key])) {
                                    let dateValue = new Date(entry.properties[key]);
                                    let dateValFormatted = entry.properties[key];
                                    if (dateValue instanceof Date && !isNaN(dateValue)) {
                                        if (dateValue.getMinutes() === 0 && dateValue.getHours() === 0) {
                                            dateValFormatted = t('date', { date: dateValue });
                                        } else {
                                            dateValFormatted = t('datetime', { date: dateValue });
                                        }
                                    }
                                    let dateReplacements = `<span>${dateValFormatted}</span>`;
                                    newDesc = newDesc.replace(regex, dateReplacements);
                                }
                                break;
                        }
                    }
                }
                let regex = new RegExp(`{{\\[${key}\\]}}`, 'g');
                if (!isEmptyValue(entry.properties[key])) {
                    newDesc = newDesc.replace(regex, entry.properties[key]);
                }
            });
        }
        const patterns = {
            createdByUserId: `{{\\{baseUserCreated\\}}}`,
            modifiedByUserId: `{{\\{baseUserUpdated\\}}}`,
            created: `{{\\{baseDateCreated\\}}}`,
            updated: `{{\\{baseDateUpdated\\}}}`,
        };
        for (let type in patterns) {
            let regex = new RegExp(patterns[type], 'g');
            if (regex.test(newDesc)) {
                switch (type) {
                    case 'createdByUserId':
                    case 'modifiedByUserId':
                        let linkReplacements = `<a href="/id2entry/${entry.createdByUserId}" target="_blank" >${entry.modifiedByUserName}</a>`;
                        newDesc = newDesc.replace(regex, linkReplacements);
                        break;
                    case 'created':
                    case 'updated':
                        if (!isUndefined(entry[type])) {
                            let dateValue = new Date(entry[type]);
                            let dateValFormatted = entry[type];
                            if (dateValue instanceof Date && !isNaN(dateValue)) {
                                if (dateValue.getMinutes() === 0 && dateValue.getHours() === 0) {
                                    dateValFormatted = t('date', { date: dateValue });
                                } else {
                                    dateValFormatted = t('datetime', { date: dateValue });
                                }
                            }
                            let dateReplacements = `<span>${dateValFormatted}</span>`;
                            newDesc = newDesc.replace(regex, dateReplacements);
                        }
                        break;
                }
            }
        }
        const leftoverPattern = /{{\([^}]+\)}}|{{\{[^}]+\}}}|{{\[[^}]+\]}}/g;
       newDesc = newDesc.replace(leftoverPattern, '');

    }
    return newDesc;
}