import attributes from './attributes.json';
import header from './header.json';
import view from './view.json';
import edit from './edit.json';
import newEntry from './new.json';
import deleteEntry from './delete.json';
import archive from './archive.json';
import tools from './tools.json';
import descriptionLayout from './descriptionLayout.json';
export default {
    "entry": {
        ...attributes,
        ...header,
        ...view,
        ...edit,
        ...newEntry,
        ...deleteEntry,
        ...archive,
        ...descriptionLayout,
        ...tools
    }
}