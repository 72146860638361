import header from './header/header.json';
import advancedSearch from './search/advancedSearch.json';
import bpmn from './bpmn/bpmn.json';
import userDashboard from './userDashboard/userDashboard.json';
import userPage from './userPage/userPage.json';
import entry from './entry/index.js';
import lineage from './lineage/lineage.json';
import setup from './setup/index.js';
import genericControls from './genericControls/genericControls.json';
import colorPicker from './colorPicker/colorPicker.json';
import defaultTable from './defaultTable/defaultTable.json';
import iconPicker from './iconPicker/iconPicker.json';
let languageEN = {
    "translations": {
        "datetime": "{{date, LLL}}",
        "datetimeFormat": "YYYY-MM-DD HH:mm:ss",
        "dateFormat": "YYYY-MM-DD",
        "dateFromNow": "{{date, FROM_NOW}}",
        "date": "{{date, l}}",
        "number": "{{number}}",
        "app": {
            ...defaultTable,
            ...genericControls,
            ...colorPicker,
            ...iconPicker,
            ...header,
            ...advancedSearch,
            ...bpmn,
            ...userDashboard,
            ...userPage,
            ...entry,
            ...lineage
        },
        ...setup
    }
}

export default languageEN;
